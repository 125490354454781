import { Period, useEngagementStats } from '@bpm-web-app/swr-hooks';
import { capitalizeFirstLetter, getBackgroundColor, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { Swiper, SwiperSlide, SwiperClass as SwiperConfig } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from '../../../dropdown/dropdown';
import { AnalyticsCard } from '../analytics-card/analytics-card';
import { TitleView } from '../title-view/title-view';

import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';

import styles from './analytics-engagement.module.css';
import { AnalyticsCardGhostLoading } from '../analytics-card/analytics-card-ghost-loading';
import GhostElement from '../../../shared/ghost-element/ghost-element';

export function AnalyticEngagement() {
    const [period, setPeriod] = useState<Period>('week');
    const [key, setKey] = useState<string>('swiper-key');
    const [, setSwiper] = useState<SwiperConfig>();
    const allPeriods: Period[] = ['day', 'week', 'month'];
    const { data } = useEngagementStats(period);

    const { isMobile } = useViewport();

    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const engagementData = useMemo(() => {
        if (data?.data) {
            const {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                est_reach,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                est_reach_percentage,
                playlists,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                playlists_percentage,
                impressions,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                impressions_percentage,
                views,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                views_percentage,
                followers,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                followers_percentage,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                link_clicks,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                link_clicks_percentage
            } = data.data;
            return [
                { title: 'Estimated Reach', tooltip: 'Your estimated audience based on DJs who downloaded your music.', value: est_reach, percent_changed: est_reach_percentage },
                { title: 'Impressions', tooltip: 'Your total number of views received this week.', value: impressions, percent_changed: impressions_percentage },
                { title: 'Profile Visits', tooltip: 'Your total number of profile visits this week.', value: views, percent_changed: views_percentage },
                { title: 'Link Clicks', tooltip: 'The total number of clicks on your profile this week.', value: link_clicks, percent_changed: link_clicks_percentage },
                { title: 'Followers', tooltip: 'Your total number of followers on BPM Supreme.', value: followers, percent_changed: followers_percentage },
                { title: 'Playlists', tooltip: 'Your total number of playlist features on BPM Supreme.', value: playlists, percent_changed: playlists_percentage },
            ];
        }
        return undefined;
    }, [data]);

    const isLoading = useMemo(() => {
        if (engagementData === undefined || engagementData === null) {
            return true;
        }
        return false;
    }, [engagementData]);

    const titleViewEngagement = <TitleView
        title="Engagement"
        subtitle="View how BPM Supreme users are engaging with you and your content."
        arrowsComponent={
            isMobile ?
                (
                    <div className={styles['analytics-engagement--mobile-right-component']}>
                        <button type="button" aria-label="Previous Page" ref={prevButtonRef} className={styles['analytics-engagement__arrow-btn']}>
                            <ChevronLeft />
                        </button>
                        <button type="button" aria-label="Next Page" ref={nextButtonRef} className={classNames(styles['analytics-engagement__arrow-btn'], styles['analytics-engagement__mobile-prev-btn'])}>
                            <ChevronRight />
                        </button>
                    </div>
                ) : null
        }
        rightComponent={
            data !== undefined ? (
                <div className={styles['analytics-engagement--right-component']}>
                    <Dropdown<Period>
                        onClick={(option) => {
                            setPeriod(option);
                        }}
                        value={period}
                        useNewMobileStyle
                        options={[
                            ...allPeriods.map((p) => ({
                                label: capitalizeFirstLetter(p),
                                value: p
                            }))
                        ]}
                    />
                    {isMobile
                        ? null
                        :
                        <div>
                            <button type="button" aria-label="Previous Page" ref={prevButtonRef} className={classNames(styles['analytics-engagement__arrow-btn'], styles['analytics-engagement__prev-btn'])}>
                                <ChevronLeft />
                            </button>
                            <button type="button" aria-label="Next Page" ref={nextButtonRef} className={classNames(styles['analytics-engagement__arrow-btn'], styles['analytics-engagement__next-btn'])}>
                                <ChevronRight />
                            </button>
                        </div>
                    }
                </div>
            ) : isMobile ? <GhostElement itemClass={styles['analytics-engagement--ghost-dropdown']} /> : null
        } />;

    useEffect(() => {
        if (isMobile) { setKey('mobile-swiper-key'); } else {
            setKey('swiper-key');
        }
    }, [isMobile]);

    return (
        <div className={styles['analytics-engagement']}>
            {titleViewEngagement}
            <Swiper
                modules={[Navigation]}
                spaceBetween={16}
                slidesPerView="auto"
                slidesPerGroupAuto
                threshold={8}
                key={key}
                navigation={{
                    prevEl: prevButtonRef.current,
                    nextEl: nextButtonRef.current,
                }}
                onSwiper={setSwiper}
            >
                {!isLoading ?
                    engagementData.map((slide, index) => (
                        <SwiperSlide key={slide.title}>
                            <AnalyticsCard title={slide.title} value={slide.value} percent_changed={slide.percent_changed} tooltip={slide.tooltip} color={getBackgroundColor(index)} variant="large" />
                        </SwiperSlide>
                    )
                    ) :
                    [1, 2, 3, 4, 5].map((index) => (
                        <SwiperSlide key={index}>
                            <AnalyticsCardGhostLoading variant="large" />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}
