import { Album as DownloadAlbum, ArtistWithAlbumCount, CuratedSet as DownloadCuratedSet, Playlist } from '@bpm-web-app/download-api-sdk';
import { Album, Artist, ArtistWithMediaCount, Category, CuratedSet, Genre, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { appendQueryParams, convertToPluralIfNeeded, downloadAlbumWithMediaToQueueItem, QueueItem, streamMediaWithAlbumToQueueItem, useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperClass as SwiperConfig } from 'swiper/react';
import ListGridItem from '../../../shared/list-grid-item/list-grid-item';
import ListGrid from '../../../shared/ui/list-grid/list-grid';
import { usePlayer } from '../../../player-context';
import styles from './favorites-mobile.module.css';
import { useArtistLinks } from '../../../artist-link/artist-link';
import { useCuratedSetCategoryLinks, useGenreLinks, usePlaylistCategoryLinks } from '../../../generate-link/generate-link';
import { FAVORITE_EMPTY_STATES, FavoriteEmptySpaceProps } from '../favorites-desktop/favorites-desktop';
import { EmptyState } from '../../../shared';

interface FavoritesMobileProps {
    favAlbums: Album[] | null;
    favMedia: MediaWithAlbum[] | DownloadAlbum[];
    favPlaylists: Playlist[];
    favCuratedSets: CuratedSet[] | DownloadCuratedSet[];
    favArtists: ArtistWithAlbumCount[] | ArtistWithMediaCount[];
}

export enum FavoritesMobileTabs {
    Artists = 'Followed Artists',
    Songs = 'Favorite Songs',
    Albums = 'Favorite Albums',
    Playlists = 'Followed Playlists',
    CuratedSets = 'Favorite Curated Sets',
}

export default function FavoritesMobile({ favAlbums, favMedia, favPlaylists, favCuratedSets, favArtists }: FavoritesMobileProps) {
    const [activeTab, setActiveTab] = useState(FavoritesMobileTabs.Artists);
    const [, setSwiper] = useState<SwiperConfig>();
    const { isDownload } = useHubSwitch();
    const { setQueue } = usePlayer();
    const generateArtistLinks = useArtistLinks();
    const generateGenreLinks = useGenreLinks();
    const generateCuratorSetCategoryLinks = useCuratedSetCategoryLinks();
    const generatePlaylistCategoryLinks = usePlaylistCategoryLinks();

    useEffect(() => {
        if (isDownload && activeTab === FavoritesMobileTabs.Albums) {
            setActiveTab(FavoritesMobileTabs.Songs);
        }
    }, [activeTab, isDownload]);

    const getNoResultsSection = useCallback(
        ({ emptyStateProps }: FavoriteEmptySpaceProps) => (
            <EmptyState {...emptyStateProps} actionIcon="chevron-right-icon" noPadding />
        ),
        []
    );

    const handlePlaySongs = useCallback(
        (indexToPlay: number) => {
            let queueItems: QueueItem[];

            if (isDownload) {
                queueItems = (favMedia as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album));
            } else {
                queueItems = (favMedia as MediaWithAlbum[]).map((media) => streamMediaWithAlbumToQueueItem(media));
            }

            setQueue(queueItems, indexToPlay);
        },
        [favMedia, isDownload, setQueue]
    );

    const handlePlayStreamAlbum = useCallback(
        (albumIndex: number) => {
            if (isDownload) return;
            const queueItems = favAlbums[albumIndex].media.map((media) => streamMediaWithAlbumToQueueItem(media));

            setQueue(queueItems);
        },
        [favAlbums, isDownload, setQueue]
    );

    const getDescriptionMarkup = useCallback((artist: string, artists: Artist[], genre: Genre) => (
        <>
            <div className={styles['favorites-mobile__artist']}>{generateArtistLinks(artist, artists)}</div>
            <div className={styles['favorites-mobile__card-details']}>
                <span>{generateGenreLinks(genre)}</span>
            </div>
        </>
    ), [generateArtistLinks, generateGenreLinks]);

    const getDescriptionMarkupCuratorSetCategory = useCallback((artist: string, artists: Artist[], category: Category) => (
        <>
            <div className={styles['favorites-mobile__artist']}>{generateArtistLinks(artist, artists)}</div>
            <div className={styles['favorites-mobile__card-details']}>
                <span>{generateCuratorSetCategoryLinks(category)}</span>
            </div>
        </>
    ), [generateArtistLinks, generateCuratorSetCategoryLinks]);

    const getDescriptionMarkupPlaylistCategory = useCallback((artist: string, artists: Artist[], category: Category) => (
        <>
            <div className={styles['favorites-mobile__artist']}>{generateArtistLinks(artist, artists)}</div>
            <div className={styles['favorites-mobile__card-details']}>
                <span>{generatePlaylistCategoryLinks(category)}</span>
            </div>
        </>
    ), [generateArtistLinks, generatePlaylistCategoryLinks]);

    const handlerArtists = useMemo(
        () =>
            favArtists?.length > 0
                ? favArtists.map((item) => (
                    <Fragment key={item.id}>
                        <ListGridItem
                            id={item.id}
                            title={item.name}
                            subtitle={
                                isDownload
                                    ? convertToPluralIfNeeded((item as ArtistWithAlbumCount).album_count, 'Track', 'Tracks')
                                    : convertToPluralIfNeeded((item as ArtistWithMediaCount).media_count, 'Track', 'Tracks')
                            }
                            rounded
                            imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 56 })}
                            imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 112 })}
                            link={`/artist/${item.slug}`}
                            contentType="artist"
                        />
                    </Fragment>
                ))
                : getNoResultsSection(FAVORITE_EMPTY_STATES[0]),
        [favArtists, getNoResultsSection, isDownload]
    );

    const handlerSongs = useMemo(
        () =>
            favMedia.length > 0
                ? favMedia.map((item, index) => {
                    const category = isDownload ? (item as DownloadAlbum).category : (item as MediaWithAlbum).album?.category;

                    const coverUrl = isDownload ? (item as DownloadAlbum)?.cover_url : (item as MediaWithAlbum).album?.cover_url;

                    return (
                        <Fragment key={item.id}>
                            <ListGridItem
                                id={item.id}
                                title={item.title}
                                subtitle={getDescriptionMarkupCuratorSetCategory(item.artist, item.artists, category)}
                                imageUrl={appendQueryParams(coverUrl, { key: 'dw', value: 56 })}
                                imageUrl2x={appendQueryParams(coverUrl, { key: 'dw', value: 112 })}
                                link={`/curated-set/${item.id}`}
                                song={item}
                                isPlayable
                                onPlayItem={() => handlePlaySongs(index)}
                                contentType={isDownload ? 'download:album' : 'stream:album'}
                            />
                        </Fragment>
                    );
                })
                : getNoResultsSection(FAVORITE_EMPTY_STATES[1]),
        [favMedia, getDescriptionMarkupCuratorSetCategory, getNoResultsSection, handlePlaySongs, isDownload]
    );

    const handlerAlbums = useMemo(
        () =>
            favAlbums && favAlbums.length > 0
                ? favAlbums.map((item, index) => (
                    <Fragment key={item.id}>
                        <ListGridItem
                            id={item.id}
                            title={item.name}
                            subtitle={getDescriptionMarkup(item.artist, item.artists, item.genre)}
                            imageUrl={appendQueryParams(item.cover_url, { key: 'dw', value: 56 })}
                            imageUrl2x={appendQueryParams(item.cover_url, { key: 'dw', value: 112 })}
                            link={`/album/${item.id}`}
                            song={item.media[0]}
                            songsList={item.media}
                            isPlayable
                            onPlayItem={() => handlePlayStreamAlbum(index)}
                            contentType={isDownload ? 'download:album' : 'stream:album'}
                        />
                    </Fragment>
                ))
                : getNoResultsSection(FAVORITE_EMPTY_STATES[2]),
        [favAlbums, getDescriptionMarkup, getNoResultsSection, handlePlayStreamAlbum, isDownload]
    );

    const handlerPlaylists = useMemo(
        () =>
            favPlaylists.length > 0
                ? favPlaylists.map((item) => (
                    <Fragment key={item.id}>
                        <ListGridItem
                            id={item.id}
                            title={item.title}
                            subtitle={getDescriptionMarkupPlaylistCategory(item.artist, item.artists, item.category)}
                            imageUrl={appendQueryParams(item.image_url, { key: 'dw', value: 56 })}
                            imageUrl2x={appendQueryParams(item.image_url, { key: 'dw', value: 112 })}
                            link={`/exclusive-playlist/${item.id}`}
                            contentType="exclusive-playlist"
                        />
                    </Fragment>
                ))
                : getNoResultsSection(FAVORITE_EMPTY_STATES[3]),
        [favPlaylists, getDescriptionMarkupPlaylistCategory, getNoResultsSection]
    );

    const handlerCuratedSets = useMemo(
        () =>
            favCuratedSets.length > 0
                ? favCuratedSets.map((item) => (
                    <Fragment key={item.id}>
                        <ListGridItem
                            id={item.id}
                            title={item.title}
                            subtitle={item.artist}
                            imageUrl={appendQueryParams(item.image_url, { key: 'dw', value: 56 })}
                            imageUrl2x={appendQueryParams(item.image_url, { key: 'dw', value: 112 })}
                            link={`/curated-set/${item.id}`}
                            contentType="curatedSet"
                        />
                    </Fragment>
                ))
                : getNoResultsSection(FAVORITE_EMPTY_STATES[4]),
        [favCuratedSets, getNoResultsSection]
    );

    const tabs = useMemo(
        () =>
            [
                {
                    id: FavoritesMobileTabs.Artists,
                    label: FavoritesMobileTabs.Artists,
                    counter: favArtists.length || 0,
                    handler: () => handlerArtists,
                },
                {
                    id: FavoritesMobileTabs.Songs,
                    label: FavoritesMobileTabs.Songs,
                    counter: favMedia.length || 0,
                    handler: () => handlerSongs,
                },
                {
                    id: FavoritesMobileTabs.Albums,
                    label: FavoritesMobileTabs.Albums,
                    counter: favAlbums?.length || 0,
                    handler: () => handlerAlbums,
                },
                {
                    id: FavoritesMobileTabs.Playlists,
                    label: FavoritesMobileTabs.Playlists,
                    counter: favPlaylists.length || 0,
                    handler: () => handlerPlaylists,
                },
                {
                    id: FavoritesMobileTabs.CuratedSets,
                    label: FavoritesMobileTabs.CuratedSets,
                    counter: favCuratedSets.length || 0,
                    handler: () => handlerCuratedSets,
                },
            ].filter((item) => (isDownload ? item.id !== FavoritesMobileTabs.Albums : true)),
        [
            favAlbums?.length,
            favArtists.length,
            favCuratedSets.length,
            favMedia.length,
            favPlaylists.length,
            isDownload,
            handlerAlbums,
            handlerArtists,
            handlerCuratedSets,
            handlerPlaylists,
            handlerSongs,
        ]
    );

    return (
        <>
            <Swiper modules={[Navigation]} slidesPerView="auto" onSwiper={setSwiper} className="spacing--left-first-child">
                {tabs.map(({ id, label, counter }) => (
                    <SwiperSlide className={styles['favorites-mobile__tab-wrapper']} key={id}>
                        <button type="button" className={classNames(styles['favorites-mobile__tab'], { [styles['favorites-mobile__tab--active']]: activeTab === id })} onClick={() => setActiveTab(id)}>
                            {label}
                            <span>{` (${counter})`}</span>
                        </button>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={classNames('spacing--left', 'spacing--right')}>
                <ListGrid>{tabs.find(({ id }) => id === activeTab)?.handler()}</ListGrid>
            </div>
        </>
    );
}
