/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/dot-notation */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useBanner } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import { Analytics, appendQueryParams, isUrlExternal, useUniqueArray, useViewport } from '@bpm-web-app/utils';
import { Banner as CreateBanner } from '@bpm-web-app/create-api-sdk';
import { Banner as StreamBanner } from '@bpm-web-app/stream-api-sdk';
import { Platform } from '@bpm-web-app/api-client';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import styles from './banner.module.css';
import { AppLink } from '../app-link/app-link';
import { GhostComponent } from '../ghost-component/ghost-component';

export interface BannerProps {
    platform: Platform;
    library?: string;
    location?: string;
    variant?: 'ghost' | 'hidden'
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function Banner({ platform, location, library, variant = 'ghost', isDataLoaded, setIsDataLoaded }: BannerProps) {
    const { data, isLoading } = useBanner(platform, location, library);
    const { isMobile } = useViewport();
    const [swiper, setSwiper] = useState<SwiperClass>();

    const pagination: PaginationOptions = {
        clickable: true,
        el: `.${styles['banner__pagination']}`,
        renderBullet(index: number, className: string) {
            return `<button type="button" class="${classNames(styles['banner__pagination-item'], className)}" aria-label="Go to news slide ${index + 1}"></button>`;
        },
    };

    const banners = useMemo<CreateBanner['BannerImages']>(() => {
        if (platform === 'create') {
            return (data?.data as CreateBanner)?.BannerImages || [];
        }

        return (data?.data as StreamBanner)?.images || [];
    }, [data?.data, platform]);

    const ghostLoadingItems = useUniqueArray(5);

    useEffect(() => {
        if (swiper) {
            setTimeout(() => {
                swiper.update();
                swiper.slideTo(banners?.length, 0);
            }, 200);
        }
    }, [swiper, banners]);

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    if (shouldDisplayGhostLoading && variant === 'ghost') {
        return (
            <>
                <div className={classNames(styles['banner'], styles['banner__loading'], 'spacing__window--left', 'spacing__window--top')}>
                    {ghostLoadingItems.map((uuid) => (
                        <GhostComponent type="banner" />
                    ))}
                </div>
                <div className={styles['banner__pagination']} />
            </>
        );
    }

    if (!banners?.length && variant === 'hidden') {
        return null;
    }

    const isLooping = banners?.length > 1;

    return (
        <div className={classNames('spacing__window--top', styles['banner'], { [styles['banner--create']]: platform === 'create' })}>
            <Swiper
                className={classNames({ [styles['banner__list-centered']]: !isLooping }, 'spacing--left-first-child')}
                modules={[Pagination, Autoplay]}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                loop={isLooping}
                pagination={pagination}
                slidesPerView="auto"
                slidesPerGroupSkip={1}
                spaceBetween={16}
                onSwiper={setSwiper}>
                {banners &&
                    banners.map(({ id, link, image_url_large, image_url_small, video_url_small, video_url_large }) => (
                        /* eslint-disable jsx-a11y/anchor-is-valid */
                        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
                        <SwiperSlide key={id}>
                            <AppLink href={link || ''}>
                                <a
                                    className={styles['banner__list-item']}
                                    draggable={false}
                                    target={isUrlExternal(link || '') ? '_blank' : undefined}
                                    rel="noreferrer"
                                    role="banner"
                                    onKeyDown={() => Analytics.trackClick('banner', id)}
                                    onClick={() => Analytics.trackClick('banner', id)}>
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {video_url_small && video_url_large ? (
                                        isMobile ? (
                                            // eslint-disable-next-line jsx-a11y/media-has-caption
                                            <video autoPlay loop draggable={false} src={video_url_small} muted playsInline webkit-playsinline controls={false} poster={image_url_small} />
                                        ) : (
                                            // eslint-disable-next-line jsx-a11y/media-has-caption
                                            <video autoPlay loop draggable={false} src={video_url_large} muted playsInline webkit-playsinline controls={false} poster={image_url_large} />
                                        )
                                    ) : (
                                        <picture>
                                            <source srcSet={`${appendQueryParams(image_url_large, { key: 'dw', value: 640 })}, ${appendQueryParams(image_url_large, { key: 'dw', value: 640 * 2 })} 2x`} media="(min-width: 1024px)" />
                                            <source srcSet={`${appendQueryParams(image_url_small, { key: 'dw', value: 325 })}, ${appendQueryParams(image_url_small, { key: 'dw', value: 325 * 2 })} 2x`} media="(min-width: 768px)" />
                                            {isMobile && <img src={appendQueryParams(image_url_small, { key: 'dw', value: 325 })} srcSet={`${appendQueryParams(image_url_small, { key: 'dw', value: 325 * 2 })} 2x`} alt="" draggable={false} />}
                                            {!isMobile && <img src={appendQueryParams(image_url_large, { key: 'dw', value: 640 })} srcSet={`${appendQueryParams(image_url_large, { key: 'dw', value: 640 * 2 })} 2x`} alt="" draggable={false} />}
                                        </picture>
                                    )}
                                </a>
                            </AppLink>
                        </SwiperSlide>
                        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
                        /* eslint-enable jsx-a11y/anchor-is-valid */
                    ))}
            </Swiper>
            <div className={styles['banner__pagination']} />
        </div>
    );
}

export default Banner;
